import React from "react";

export const ArrowIcon = (props) => (
  <svg
    fill="none"
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 14h24M14 2l12 12-12 12"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.5"
    />
  </svg>
);

export const EmailIcon = (props) => (
  <svg
    fill="none"
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26 18a2.667 2.667 0 01-2.667 2.667h-16L2 26V4.667A2.667 2.667 0 014.667 2h18.666A2.667 2.667 0 0126 4.667V18z"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.5"
    />
  </svg>
);

export const CommunityIcon = () => (
  <svg
    fill="none"
    height="26"
    viewBox="0 0 32 26"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 24v-2.333c0-1.238-.527-2.425-1.465-3.3C19.598 17.492 18.326 17 17 17H7c-1.326 0-2.598.492-3.536 1.367-.937.875-1.464 2.062-1.464 3.3V24M12 12a5 5 0 100-10 5 5 0 000 10zM30 24v-2.385c0-1.057-.37-2.084-1.048-2.919A5.023 5.023 0 0026.25 17M21.25 2a5 5 0 010 9.688"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.5"
    />
  </svg>
);

export const ExternalIcon = () => (
  <svg
    fill="none"
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 15.333v8A2.667 2.667 0 0119.333 26H4.667A2.667 2.667 0 012 23.333V8.667A2.667 2.667 0 014.667 6h8M18 2h8v8M11.333 16.667L26 2"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.5"
    />
  </svg>
);

export const DownloadIcon = () => (
  <svg
    fill="none"
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26 18v5.333A2.667 2.667 0 0123.333 26H4.667A2.667 2.667 0 012 23.333V18M7.333 11.333L14 18l6.667-6.667M14 18V2"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.5"
    />
  </svg>
);

export const Caret = () => (
  <svg
    fill="none"
    height="15"
    viewBox="0 0 10 15"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 13.5l6-6-6-6"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />
  </svg>
);
