// import { css } from '@emotion/core'
import styled from "@emotion/styled";

import { gradientText } from "./Gradient";
import { H3 } from "./Text";

const SubTitle = gradientText(
  styled(H3)`
    max-width: 80%;

    @media only screen and (max-width: 520px) {
      max-width: 90%;
    }
  `
);

export default SubTitle;

export const SuperTitle = gradientText(styled(H3)``);
