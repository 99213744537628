import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { lighten, rem, transparentize } from "polished";
import React from "react";

import { pickColor } from "../Base";
import Flex from "../Flex";
import { Label } from "../Text";

interface SegmentedControlOptionProps {
  onClick?: () => void;
  title?: string;
  disabled?: boolean;
  selected?: boolean;
}

const OptionContainer = styled(Flex)<SegmentedControlOptionProps>`
  padding: ${rem(12)} ${rem(18)};

  border-radius: ${rem(10)};

  font-weight: 500;
  font-size: ${rem(20)};
  line-height: ${rem(24)};
  text-align: center;

  cursor: pointer;

  & > * {
    color: ${(props) => pickColor(props, props?.theme?.color?.highlight)};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: normal;
      opacity: 0.5;
    `}

  ${({ selected, ...props }) =>
    selected &&
    css`
      background: ${pickColor(props, props.theme?.color?.highlight)};
      box-shadow: 0rem ${rem(1)} ${rem(3)}
        ${transparentize(0.5, pickColor(props, props.theme?.color?.highlight))};

      & > * {
        color: #fff;
      }
    `}

  &:hover {
    ${({ disabled, selected, ...props }) =>
      !disabled &&
      !selected &&
      css`
        background: ${transparentize(
          0.5,
          pickColor(props, props.theme?.color?.highlight)
        )};
        box-shadow: 0rem ${rem(1)} ${rem(3)}
          ${transparentize(
            0.5,
            pickColor(props, props.theme?.color?.highlight)
          )};

        & > * {
          color: #fff;
        }
      `}
  }
`;

const Option = ({ title, ...props }: SegmentedControlOptionProps) => (
  <OptionContainer {...props}>
    <Label>{title}</Label>
  </OptionContainer>
);

const SegmentedControlContainer = styled(Flex)`
  flex: 0 0 auto;
  padding: ${rem(6)};
  border-radius: ${rem(12)};
  background: ${(props) =>
    transparentize(
      0.8,
      lighten(0.4, pickColor(props, props.theme?.color?.highlight))
    )};

  & > * {
    margin-right: ${rem(6)};
  }

  & > *:last-child {
    margin-right: 0;
  }
`;

interface SegmentedControlProps {
  options: SegmentedControlOptionProps[];
  onSelect: (string) => void;
}

const SegmentedControl = ({ options, onSelect }: SegmentedControlProps) => (
  <SegmentedControlContainer>
    {options.map((o) => (
      <Option key={o.title} onClick={() => onSelect(o.title)} {...o}></Option>
    ))}
  </SegmentedControlContainer>
);

export default SegmentedControl;
