import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { rem, transparentize } from "polished";
import React, { Component } from "react";

import { pickColor } from "../Base";

export interface TextProps {
  center?: boolean;
  bold?: boolean;
  light?: boolean;
  italic?: boolean;

  color?: string;
  fontSize?: number;
}

export const Text = styled.div<TextProps>`
  text-align: ${(props) => props.center && "center"};

  font-size: ${({ fontSize }) => fontSize && rem(fontSize)};
  font-weight: ${({ bold, light }) => (bold ? "600" : light ? "400" : "500")};
  line-height: 1.2;

  font-style: ${({ italic }) => (italic ? "italic" : "normal")};

  color: ${pickColor};
`;

Text.defaultProps = {
  fontSize: 16,
};

export interface LinkProps {
  as?: Component<any>;
  to?: string;
}

export interface LabelProps {
  small?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Label = styled(Text)<LabelProps & Partial<LinkProps>>`
  font-size: ${({ small }) => rem(small ? 16 : 20)};
  line-height: 1;
  text-decoration: none;

  cursor: ${({ onClick }) => onClick && "pointer"};
`;

export const Copy = styled.p`
  font-size: 1.5rem;
`;

export const P = styled(Text)`
  font-size: ${rem(20)};
  font-weight: 400;
  line-height: ${rem(34)};

  color: ${(props) => pickColor(props, props?.theme?.color?.body)};

  &:last-of-type {
    margin-bottom: 0;
  }
`.withComponent("p");

export const FinePrint = styled.p`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.4);
`;

export const H1 = styled.h1<TextProps>`
  text-align: ${(props) => props.center && "center"};

  @media only screen and (max-width: 520px) {
    font-size: 4.2rem;
  }
`;

export const H2 = styled(Text)`
  font-size: ${rem(48)};

  @media only screen and (max-width: 520px) {
    font-size: 3.2rem;
  }
`.withComponent("h2");

export const H3 = styled(Text)`
  font-size: ${rem(30)};
  line-height: ${rem(42)};
`.withComponent(motion.h3);

export const H4 = styled(Text)`
  font-size: ${rem(24)};
  line-height: ${rem(30)};
`.withComponent("h4");

export const H5 = styled(Text)`
  font-size: ${rem(20)};
  font-weight: 500;

  margin-bottom: ${rem(16)};
`.withComponent("h5");

export const Semi = styled.span`
  font-weight: 500;
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const A = styled.a<TextProps>`
  color: ${(props) => props.color || props.theme.color.highlight};
`;

export const Tag = styled(Label)<TextProps>`
  font-size: ${rem(20)};
  padding: ${rem(8)} ${rem(17)};
  background-color: ${(props) => transparentize(0.9, pickColor(props))};
  color: ${(props) => pickColor(props)};
  border-radius: ${rem(15)};
`;
