import React, { ComponentProps } from "react";

export const Twitter = ({
  color = "#000",
  ...props
}: ComponentProps<"svg">) => (
  <svg
    fill="none"
    height="26"
    viewBox="0 0 32 26"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.88946 25.3922C21.7563 25.3922 28.247 15.6222 28.247 7.14975C28.247 6.87225 28.247 6.596 28.2281 6.321C29.4908 5.41339 30.5808 4.2896 31.447 3.00225C30.2695 3.52075 29.0204 3.86078 27.7413 4.011C29.0882 3.20973 30.0962 1.94946 30.5778 0.46475C29.3113 1.21157 27.9258 1.73789 26.4809 2.021C25.5081 0.993106 24.2216 0.31246 22.8203 0.0843916C21.419 -0.143677 19.9812 0.0935455 18.7293 0.759351C17.4774 1.42516 16.4812 2.48242 15.8948 3.76755C15.3085 5.05267 15.1647 6.494 15.4858 7.8685C12.9206 7.74071 10.4112 7.07829 8.12043 5.92424C5.82963 4.77019 3.80866 3.1503 2.18871 1.16975C1.36366 2.5812 1.11096 4.25208 1.48207 5.84217C1.85318 7.43227 2.8202 8.82203 4.18623 9.7285C3.15947 9.69826 2.1551 9.42301 1.25788 8.926C1.25788 8.95225 1.25788 8.97975 1.25788 9.00725C1.25829 10.4875 1.77394 11.9221 2.71738 13.0676C3.66081 14.2132 4.97395 14.9991 6.43406 15.2922C5.4842 15.5497 4.48759 15.5873 3.52081 15.4022C3.93309 16.6762 4.73575 17.7903 5.81653 18.5886C6.89731 19.3869 8.20216 19.8296 9.54857 19.8547C7.26393 21.639 4.44165 22.6077 1.53587 22.6047C1.02254 22.6038 0.509698 22.5729 0 22.5122C2.95053 24.3938 6.38364 25.3919 9.88946 25.3872"
      fill={color}
    />
  </svg>
);

export const Facebook = ({
  color = "#000",
  ...props
}: ComponentProps<"svg">) => (
  <svg
    fill="none"
    height="30"
    viewBox="0 0 31 30"
    width="31"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M30.5934 15C30.5934 6.71572 23.8353 0 15.4988 0C7.16235 0 0.404297 6.71572 0.404297 15C0.404297 22.4869 5.92415 28.6925 13.1403 29.8178V19.3359H9.30772V15H13.1403V11.6953C13.1403 7.93594 15.3938 5.85938 18.8418 5.85938C20.4932 5.85938 22.2206 6.15234 22.2206 6.15234V9.84375H20.3172C18.4421 9.84375 17.8574 11 17.8574 12.1862V15H22.0437L21.3745 19.3359H17.8574V29.8178C25.0735 28.6925 30.5934 22.4869 30.5934 15Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export const LinkedIn = ({
  color = "#000",
  ...props
}: ComponentProps<"svg">) => (
  <svg
    fill="none"
    height="30"
    viewBox="0 0 31 30"
    width="31"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1.35504 2.73005C0.806641 3.79961 0.806641 5.19974 0.806641 8V22C0.806641 24.8003 0.806641 26.2004 1.35504 27.27C1.83743 28.2108 2.60716 28.9757 3.5539 29.455C4.6302 30 6.03915 30 8.85706 30H22.9453C25.7632 30 27.1722 30 28.2485 29.455C29.1952 28.9757 29.9649 28.2108 30.4473 27.27C30.9957 26.2004 30.9957 24.8003 30.9957 22V8C30.9957 5.19974 30.9957 3.79961 30.4473 2.73005C29.9649 1.78924 29.1952 1.02433 28.2485 0.544967C27.1722 0 25.7632 0 22.9453 0H8.85706C6.03915 0 4.6302 0 3.5539 0.544967C2.60716 1.02433 1.83743 1.78924 1.35504 2.73005ZM17.2691 11.7344H13.5217V24.9115H17.5574V18.5286C17.5574 16.3893 18.3501 15.0286 20.2555 15.0286C21.6294 15.0286 22.1696 16.2908 22.1696 18.5286V24.9115H26.2053V17.6337C26.2053 13.7041 25.2592 11.5625 21.2731 11.5625C19.1936 11.5625 17.8093 12.5296 17.2691 13.6055V11.7344ZM10.6391 24.7396H6.60337V11.5625H10.6391V24.7396ZM8.62121 9.84375C10.0539 9.84375 11.2156 8.68932 11.2156 7.26562C11.2156 5.84193 10.0539 4.6875 8.62121 4.6875C7.18854 4.6875 6.02684 5.84193 6.02684 7.26562C6.02684 8.68932 7.18854 9.84375 8.62121 9.84375Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export const YouTube = ({
  color = "#000",
  ...props
}: ComponentProps<"svg">) => (
  <svg
    fill="none"
    height="25"
    viewBox="0 0 36 25"
    width="36"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M17.873 0C18.6893 0.0011308 28.9452 0.0294007 31.582 0.736149C33.0975 1.14124 34.2908 2.33482 34.6958 3.85057C35.3951 6.46032 35.43 11.7638 35.4318 12.2877V12.3714C35.43 12.8953 35.3951 18.1986 34.6958 20.8087C34.2908 22.3243 33.0975 23.5179 31.582 23.9232C28.9727 24.6224 18.9021 24.6573 17.9016 24.6591H17.7412C16.7406 24.6573 6.67008 24.6224 4.06078 23.9232C2.54548 23.5179 1.35197 22.3243 0.946929 20.8087C0.240332 18.1711 0.212068 12.7833 0.210938 12.3565V12.3026C0.212068 11.8758 0.240332 6.4878 0.946929 3.85057C1.35197 2.33482 2.54548 1.14124 4.06078 0.736149C6.69755 0.0294007 16.9535 0.0011308 17.7697 0H17.873ZM14.2192 7.12547V17.5336L23.4247 12.3298L14.2192 7.12547Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export const DeFiPulse = ({
  color = "#000",
  ...props
}: ComponentProps<"svg">) => (
  <svg
    fill="none"
    height="5581"
    viewBox="0 0 4745 5581"
    width="4745"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M1467 0H0V4749L2.11328 4749.42L2 4750.77L832.482 5581H2274.06C3756.5 5581 4745 4572 4745 3195.41C4745 2311 4371.5 1773.5 4060 1462C3982.84 1384.84 3918.59 1319.81 3855.5 1255.96L3854.99 1255.44C3706.43 1105.08 3564.18 961.093 3274.83 680.408L3272.63 680.181C2837.22 258.067 2217.66 0 1467 0ZM308.121 4774H1467C2998 4774 3974 3743 3974 2403C3974 2079.9 3916.82 1772.9 3808.67 1493.02C3842.88 1527.53 3879 1563.84 3918.58 1603.42C4201.91 1886.75 4545 2375.93 4545 3195.41C4545 3834.49 4316.38 4377.99 3925.37 4761.67C3534.34 5145.37 2968.79 5381 2274.06 5381H915.307L308.121 4774ZM1482.28 749H779V4043H1482.28C1485.52 4043 1488.76 4042.99 1492 4042.98V1428H2311.14C2572.46 1428 2805.33 1484.02 3002.56 1583.32C2714.64 1033.29 2125.81 749 1482.28 749ZM3126.5 1902.85C2923.26 1731.11 2646.09 1628 2311.14 1628H1692V4033.01C2524.6 3952.55 3188 3387.75 3188 2392.04C3188 2215.08 3166.48 2051.94 3126.5 1902.85Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export const Discord = ({
  color = "#000",
  ...props
}: ComponentProps<"svg">) => (
  <svg
    fill="none"
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40 12s-4.586-3.59-10-4l-.488.977C34.406 10.176 36.652 11.89 39 14c-4.047-2.066-8.04-4-15-4-6.96 0-10.953 1.934-15 4 2.348-2.11 5.02-4.016 9.488-5.023L18 8c-5.68.535-10 4-10 4s-5.121 7.426-6 22c5.16 5.953 13 6 13 6l1.64-2.184C13.856 36.848 10.716 35.121 8 32c3.238 2.45 8.125 5 16 5s12.762-2.55 16-5c-2.715 3.121-5.855 4.848-8.64 5.816L33 40s7.84-.047 13-6c-.879-14.574-6-22-6-22zM17.5 30c-1.934 0-3.5-1.79-3.5-4s1.566-4 3.5-4 3.5 1.79 3.5 4-1.566 4-3.5 4zm13 0c-1.934 0-3.5-1.79-3.5-4s1.566-4 3.5-4 3.5 1.79 3.5 4-1.566 4-3.5 4z"
      fill={color}
    />
  </svg>
);
