import { BigNumber } from "ethers";
import { atom, atomFamily, selector } from "recoil";

export { chainIdAtom } from "../modules/config";

export const pendingEthTxAtom = atom({
  key: "pendingEthTx",
  default: [],
});

export const tokenBalanceAtomFamily = atomFamily({
  key: "tokenBalance",
  default: BigNumber.from(0),
});

export const ampPriceAtom = atom({
  key: "ampPrice",
  default: 0.005,
});

export const activeAtom = atom({
  key: "ActiveAtom",
  default: false,
});

export const accountAtom = atom({
  key: "Account",
  default: new Promise<string>(() => {}),
});

export const fxcBalanceAtom = selector({
  key: "fxcBalance",
  get: ({ get }) => get(tokenBalanceAtomFamily("fxc")),
});

export const ampBalanceAtom = selector({
  key: "ampBalance",
  get: ({ get }) => get(tokenBalanceAtomFamily("amp")),
});

export const accountQueryNonceAtom = atom({
  key: "accountQueryNonce",
  default: 0,
});

export const waitingOnMetaMaskAtom = atom({
  key: "waitingOnMetaMask",
  default: false,
});

export const migrateModalSeenAtom = atom({
  key: "migrateModalSeen",
  default: false,
});

export const autoConnectTriedAtom = atom({
  key: "autoConnectTriedAtom",
  default: false,
});
