import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

/** component */
export const Gradient = ({ component, start, end, ...props }) => {
  const Component = styled(component)``;
  return (
    <Component
      {...props}
      css={css`
        background-image: linear-gradient(
          17.51deg,
          ${start} 8.9%,
          ${end} 70.03%
        );

        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      `}
    />
  );
};

export const gradientText = (el) => styled(el)`
  background-image: linear-gradient(
    17.51deg,
    var(--gradient-start) 8.9%,
    var(--gradient-end) 70.03%
  );

  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const gradientBackgroundCss = (start, end) => css`
  background-image: linear-gradient(
    88.42deg,
    ${start} -170.87%,
    ${end} 120.93%
  );
`;
