import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { rem } from "polished";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import Flex, { Left, Right } from "./Flex";
import Icons from "./Icons";
import Link from "./Link";
import Spacer from "./Spacer";
import { Text } from "./Text";

interface FooterSection {
  name: string;
  items: Array<{
    name: string;
    external?: boolean;
    href?: string;
  }>;
  href?: string;
}

interface FooterProps {
  sitemap?: {
    sections: FooterSection[];
  };
}

const List = styled.ul`
  list-style: none;
  /* margin: 0 4rem; */
  width: 20%;
  padding: 0;

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }

  @media only screen and (max-width: 520px) {
    width: 25%;
  }
`;

const ListItem = styled.li`
  font-size: 1.25rem;
  color: #444;

  margin-bottom: 1.375rem;

  &:first-of-type {
    font-weight: 500;
    color: #000;
  }

  & a {
    color: #444;

    :hover {
      color: var(--highlight-color);
    }
  }
`;
const socialLinks = [
  {
    name: "twitter",
    Icon: Icons.Twitter,
    text: "Flexa on Twitter",
    link: "https://twitter.com/flexahq",
  },
  {
    name: "discord",
    Icon: Icons.Discord,
    text: "Flexa on Discord",
    link: "https://flexa.network/discord",
  },
  {
    name: "defipulse",
    Icon: Icons.DeFiPulse,
    text: "Flexa on DeFi Pulse",
    link: "https://defipulse.com/flexa",
  },
];

const SocialLinks = ({ links = socialLinks }) => {
  const { color: colors } = useTheme();
  return (
    <>
      {links.map((props) => {
        const { link, Icon, name } = props;
        return (
          <a
            key={name}
            css={css`
              margin-left: ${rem(32)};
            `}
            href={link}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon
              color={colors.end}
              css={css`
                width: ${rem(30)};
                height: ${rem(30)};
              `}
            />
          </a>
        );
      })}
    </>
  );
};

const Footer = ({
  sitemap: { sections = [] } = { sections: [] },
}: FooterProps) => (
  <footer>
    <Spacer rem={3} />

    {sections && sections.length > 0 ? (
      <>
        <div
          css={css`
            display: flex;
            justify-content: flex-start;
          `}
        >
          {sections.map(({ name, items }) => (
            <List key={name}>
              <ListItem key={name}>{name}</ListItem>
              {items.map(({ name, href, external }) => (
                <ListItem key={name}>
                  <Link external={external} to={href}>
                    {name}
                  </Link>
                </ListItem>
              ))}
            </List>
          ))}
        </div>

        <Spacer rem={6} />
      </>
    ) : null}

    <Flex between>
      <Left>
        <Text
          light
          css={css`
            font-size: 1.25rem;
            color: rgba(0, 0, 0, 0.4);
          `}
        >
          © {new Date().getFullYear()} Flexa
          <span
            css={css`
              margin: 0 1.25rem;
            `}
          >
            •
          </span>
          <RouterLink
            css={css`
              color: rgba(0, 0, 0, 0.4);
              &:hover {
                color: rgba(0, 0, 0, 0.5);
              }
            `}
            to="/metrics"
          >
            Capacity metrics
          </RouterLink>
          <span
            css={css`
              margin: 0 1.25rem;
            `}
          >
            •
          </span>
          <a
            css={css`
              color: rgba(0, 0, 0, 0.4);
              &:hover {
                color: rgba(0, 0, 0, 0.5);
              }
            `}
            href="https://flexa.network/privacy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy & terms
          </a>
        </Text>
      </Left>
      <Right>
        <SocialLinks />
      </Right>
    </Flex>

    <Spacer rem={2} />
  </footer>
);

export default Footer;
