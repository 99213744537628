import { BigNumber } from "ethers";

import CapacityAPIClient, {
  AppAllocation,
  LegacyAllocation,
} from "../lib/capacity-api";
import { AccountData, LegacyAccountData } from "./account";

const FAKE = false;

interface CallOptions {
  baseUrl: string;
  fake?: boolean;
}

const options = {
  baseUrl: "",
  fake: FAKE,
};

export type AccountResult = AccountData & {
  legacy?: LegacyAccountData;
};

const parseAccountData = (data) => ({
  ...data,
  allocations:
    data.allocations?.map((a: AppAllocation) => ({
      ...a,
      supplyComplete: BigNumber.from(a.supplyComplete || 0),
      supplyPending: BigNumber.from(a.supplyPending || 0),
      unlockComplete: BigNumber.from(a.unlockComplete || 0),
      unlockPending: BigNumber.from(a.unlockPending || 0),
    })) ?? [],

  rewards: BigNumber.from(data.rewards || 0),
});

const parseLegacyAccountData = (data) => ({
  ...data,
  allocations:
    data.allocations?.map((a: LegacyAllocation) => ({
      ...a,
      amount: BigNumber.from(a.amount),
    })) ?? [],
  unallocated: BigNumber.from(data.unallocated || 0),
  rewards: BigNumber.from(data.rewards || 0),
  withdrawalAuthorizationLimit: BigNumber.from(
    data.withdrawalAuthorizationLimit || 0
  ),
  requestedWithdrawalAuthorization: BigNumber.from(
    data.requestedWithdrawalAuthorization || 0
  ),
});

export const getAccount = async (
  account: string,
  callOpts: CallOptions = options
) => {
  const api = new CapacityAPIClient(callOpts);

  const { data, error } = await api.getAccount(account);

  if (error) {
    return {
      error,
    };
  }

  const res: AccountResult = parseAccountData(data);
  if (data?.legacy) {
    res.legacy = parseLegacyAccountData(data.legacy);
  }

  return {
    data: res,
  };
};

export const updateAccountUnlocked = async (
  account: string,
  signature: string,
  requestedWithdrawalAuthorization: BigNumber,
  callOpts: CallOptions = options
) => {
  const api = new CapacityAPIClient(callOpts);

  const payload = {
    requestedFXCWithdrawalAuthorization:
      requestedWithdrawalAuthorization.toString(),
  };

  const { data, error } = await api.updateAccount(account, signature, payload);
  if (error) {
    return { error };
  }

  return { data };
};

export const getConfiguration = async (callOpts: CallOptions = options) =>
  new CapacityAPIClient(callOpts).getConfiguration();

export const listApps = async (callOpts: CallOptions = options) => {
  const api = new CapacityAPIClient(callOpts);

  const { data, error } = await api.listApps();

  if (error) {
    return {
      error,
    };
  }

  return {
    data,
  };
};
