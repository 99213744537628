import styled from "@emotion/styled";

interface SpacerProps {
  rem?: number;
}

const Spacer = styled.div<SpacerProps>`
  height: ${({ rem = 3 }) => rem}rem;
`;

export default Spacer;

export const ColSpacer = styled.div<SpacerProps>`
  width: ${({ rem = 3 }) => rem}rem;
  min-width: 1rem;
`;
