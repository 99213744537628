import { ThemeProvider } from "@emotion/react";
import React, { Suspense, lazy } from "react";
import { I18nextProvider } from "react-i18next";
import { RecoilRoot, useRecoilValue } from "recoil";

import { chainIdAtom } from "./atoms";
import { GlobalStyles, Spacer } from "./base";
import DebugOverlay from "./components/Debug";
import i18n from "./modules/i18n";
import { altTheme, localTheme, mainTheme, testnetTheme } from "./theme";

const isDevelopment = () => process.env.NODE_ENV === "development";

const Web3Provider = lazy(() => import("./components/Web3Provider"));
const Router = lazy(() => import("./components/Router/Router"));

const ThemedApp = () => {
  const chainId = useRecoilValue(chainIdAtom);
  const theme =
    chainId === 11155111
      ? testnetTheme
      : chainId === 1337
      ? localTheme
      : [2, 3, 4].includes(chainId)
      ? altTheme
      : mainTheme;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Suspense>
        <Router />
      </Suspense>
      {isDevelopment() && (false as boolean) ? (
        <>
          <DebugOverlay />
          <Spacer size={400} />
        </>
      ) : null}
    </ThemeProvider>
  );
};

const App = () => (
  <RecoilRoot>
    <I18nextProvider i18n={i18n}>
      <Suspense>
        <Web3Provider>
          <ThemedApp />
        </Web3Provider>
      </Suspense>
    </I18nextProvider>
  </RecoilRoot>
);

export default App;
