import axios, { AxiosInstance } from "axios";
import { get } from "lodash";

import FakeBackend from "./fake";
import {
  ApiResponse,
  CapacityAPIOptions,
  GetAccountResponse,
  InitResponse,
  ListAppsResponse,
} from "./types";

const defaultGetAccountResponse: GetAccountResponse = {
  allocations: [],
  rewards: "0",
};

class CapacityAPI {
  base?: AxiosInstance;
  options?: CapacityAPIOptions;

  constructor(options: CapacityAPIOptions) {
    this.options = options;
    this.base = axios.create({
      baseURL: options.baseUrl,
      headers: {
        accept: "application/vnd.flexa.capacity.v1+json",
      },
    });
  }

  async getConfiguration(): Promise<ApiResponse<InitResponse>> {
    if (this.options.fake) {
      return FakeBackend.init();
    }

    try {
      const response = await this.base.get("/configuration", {
        headers: {
          Accept: "application/vnd.flexa.capacity.v1+json",
        },
      });

      if (response.status === 451) {
        return {
          data: {
            ...response.data,
            ok: false,
          },
        };
      }

      if (response.status !== 200) {
        return {
          error: `Non 200 response code: ${response.status}`,
          data: response.data,
        };
      }

      return {
        data: {
          ...response.data,
          ok: true,
        },
      };
    } catch (error) {
      return {
        error,
      };
    }
  }

  async getAccount(account: string): Promise<ApiResponse<GetAccountResponse>> {
    if (this.options.fake) {
      return FakeBackend.getAccount();
    }

    try {
      const response = await this.base.get(`/accounts/${account}`, {
        headers: {
          Accept: "application/vnd.flexa.capacity.v1+json",
        },
      });

      if (response.status === 404) {
        return {
          error: null,
          data: defaultGetAccountResponse,
        };
      }

      if (response.status !== 200) {
        return {
          error: `Non-200 response code: ${response.status}`,
          data: defaultGetAccountResponse,
        };
      }

      const { data } = response;

      return {
        data,
      };
    } catch (error) {
      if (error?.response?.status === 404) {
        return {
          error: null,
          data: defaultGetAccountResponse,
        };
      }

      return {
        error,
        data: defaultGetAccountResponse,
      };
    }
  }

  async updateAccount(
    account: string,
    signature: string,
    payload: {
      requestedFXCWithdrawalAuthorization: string;
    }
  ): Promise<ApiResponse<any>> {
    if (this.options.fake) {
      return FakeBackend.updateAccount() as unknown as Promise<
        ApiResponse<any>
      >;
    }

    try {
      const response = await this.base.patch(
        `/accounts/${account}`,
        {
          ...payload,
        },
        {
          headers: {
            "Authorization": `Bearer ${signature}`,
            "Accept": "application/vnd.flexa.capacity.v1+json",
            "Content-Type": "application/json",
          },
        }
      );

      return { data: response.data };
    } catch (error) {
      if (
        get(error, "response.data.error", "").includes("Insufficient balance")
      ) {
        return {};
      }
      return {
        error,
      };
    }
  }

  async listApps(): Promise<ApiResponse<ListAppsResponse>> {
    if (this.options.fake) {
      return Promise.resolve({ data: { apps: [] } });
    }

    try {
      const response = await this.base.get(`/apps`, {
        headers: {
          Accept: "application/vnd.flexa.capacity.v1+json",
        },
      });

      if (response.status !== 200) {
        return {
          error: `Non-200 response code: ${response.status}`,
          data: { apps: [] },
        };
      }

      const { data } = response;

      return {
        data,
      };
    } catch (error) {
      return {
        error,
        data: { apps: [] },
      };
    }
  }
}

export default CapacityAPI;
