import { Color, Theme } from "../theme";

type Dimension = number | string;

export interface BaseProps {
  // box-sizing: border-box;
  pad?: number | string;
  borderRadius?: number | string;
  shadow?: string;

  size?: Dimension;
  width?: Dimension;
  height?: Dimension;

  bg?: Color;
  color?: Color;

  solid?: boolean;
}

type PropPickFunction<T = any> = (
  props: BaseProps & {
    theme?: Theme;
  },
  fallback?: any
) => T;

export const pickColor: PropPickFunction = (props, fallback = "#000") => {
  const { color, theme } = props;
  if (typeof color === "string" && theme?.color?.[color]) {
    return theme.color[color];
  }
  return color || fallback;
};

export const pickBg: PropPickFunction = (props, fallback = "#fff") => {
  const { bg, theme } = props;
  if (typeof bg === "string" && theme?.color?.[bg]) {
    return theme.color[bg];
  }
  return bg ?? fallback;
};
