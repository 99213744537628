import { mergeTheme } from "../base";

// import { rgba, parseToRgb, shade, saturate, darken } from 'polished'

export const mainTheme = mergeTheme({
  color: {
    start: "#953BFC",
    // middle: '#ac32d9',
    end: "#E72D9A",

    left: "#913BFF",
    right: "#D9328B",

    highlight: "#D9327C",

    body: "#555",
    text: {
      primary: "#000",
      placeholder: "#CCC",
    },

    light: "#777",
    lighter: "#ADADAD",
    lightest: "#CCC",

    logoStops: [
      "#FF2B6A",
      "#A800FE",
      "#FFFFFF",
      "#ECF4FD",
      "#D6A3FF",
      "#AB58FF",
      "#C549F1",
    ],
  },
});

export default mainTheme;

export const testnetTheme = mergeTheme(
  {
    color: {
      start: "#ED0E00",
      end: "#FC9A01",

      left: "#ED0E00",
      right: "#FFB800",

      highlight: "#FC9A01",

      text: {
        primary: "#000",
        placeholder: "#CCC",
      },

      light: "#777",
      lighter: "#ADADAD",
      lightest: "#CCC",

      logoStops: [
        "#D9327C",
        "#A800FE",
        "#ffffff",
        "#FFF8F4",
        "#FFF0A3",
        "#FFD850",
        "#F88635",
      ],
    },
  },
  mainTheme
);

export const altTheme = mergeTheme(
  {
    color: {
      start: "#e046de",
      end: "#23c3df",

      left: "#23c3df",
      right: "#23eece",

      // highlight: '#e673d2',
      highlight: "#23c3df",

      text: {
        primary: "#000",
        placeholder: "#CCC",
      },

      light: "#777",
      lighter: "#ADADAD",
      lightest: "#CCC",

      logoStops: [
        "#2e95fd",
        "#1ab8e2",
        "#00ffc7",
        "#ffffff",
        "#ebf7ff",
        "#ff98d6",
        "#fe67ce",
      ],
    },
  },
  mainTheme
);

export const localTheme = mergeTheme(
  {
    color: {
      start: "#18c8ff",
      end: "#00ddd0",

      left: "#23c3df",
      right: "#23eece",

      // highlight: '#e673d2',
      highlight: "#23c3df",

      text: {
        primary: "#000",
        placeholder: "#CCC",
      },

      light: "#777",
      lighter: "#ADADAD",
      lightest: "#CCC",

      logoStops: [
        "#00B2FE",
        "#00B2FE",
        "#FFFFFF",
        "#F1FFF5",
        "#BDFFC4",
        "#2DFFD9",
        "#00F0FF",
      ],
    },
  },
  mainTheme
);
