import { css } from "@emotion/core";
import styled, { StyledComponent } from "@emotion/styled";
import { motion } from "framer-motion";
import { rem } from "polished";
import { PropsWithChildren } from "react";

import { BaseProps, pickBg } from "../Base";

type BoxProps = PropsWithChildren<Partial<BaseProps>>;

// const remify = (val?: string | number) => (val ?? true) || rem(val)

const parseDimensions = (props: BaseProps) => {
  const { width, height, size } = props;
  const widthPx = width ?? size;
  const heightPx = height ?? size;
  return css`
    width: ${widthPx && rem(widthPx)};
    height: ${heightPx && rem(heightPx)};
  `;
};

const Box: StyledComponent<BoxProps> = styled(motion.div)<BoxProps>`
  ${parseDimensions}

  padding: ${({ pad }) => pad && rem(pad)};

  border-radius: ${(props) => props.borderRadius && rem(props.borderRadius)};
  box-shadow: ${(props) => props.shadow};
  background-color: ${(props) => pickBg(props, "")};

  ${({ solid }) =>
    solid &&
    css`
      flex: 0 0 auto;
    `}/* @media only screen and (max-width: 520px) {
    padding: 4rem;
  } */
`;

const defaultProps = {
  // padding: 5.25,
  // borderRadius: 0.5,
  // boxShadow: `0 4px 12px 0 rgba(0, 0, 0, 0.1), 0 12px 48px 0 rgba(0, 0, 0, 0.1)`
};

Box.defaultProps = defaultProps;

export default Box;

interface SpacerProps {
  size: number;
  col?: boolean;
}

export const Spacer: StyledComponent<SpacerProps> = styled.div<SpacerProps>`
  ${({ col, size }) =>
    col
      ? css`
          flex: 0 0 auto;
          width: ${rem(size)};
          min-width: ${size < 16 ? rem(size) : "1rem"};
        `
      : css`
          height: ${rem(size)};
        `}
`;

interface SeparatorProps {
  width?: number | string;
  height?: number;
  color?: string;
}

export const Separator: StyledComponent<SeparatorProps> = styled.div<SeparatorProps>`
  align-self: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.height * 0.75}px;
  background-color: ${(props) => props.color};
`;

Separator.defaultProps = {
  width: "100%",
  height: 1,
  color: "rgba(216, 216, 216, 0.4)",
};
