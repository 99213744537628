import { merge } from "lodash/fp";
import { mix } from "polished";

export type Color = string;

export interface Theme {
  color?: {
    start?: Color;
    mid?: Color;
    end?: Color;

    left?: Color;
    right?: Color;

    body?: Color;
    highlight?: Color;

    logoStops?: Color[];

    light?: Color;
    lighter?: Color;
    lightest?: Color;

    warning?: Color;
    danger?: Color;

    text?: {
      primary?: Color;
      placeholder?: Color;
    };
  };

  spacing?: any;
}

export const defaultTheme = {
  color: {
    start: "#1773fe",
    end: "#9e34ff",

    left: "#5458ff",
    right: "#8043ff",

    body: "#000",
    highlight: "#485dff",

    logoStops: [
      "#AB58FF",
      "#C549F1",
      "#FF2B6A",
      "#ffffff",
      "#ECF4FD",
      "#D6A3FF",
    ],

    text: {
      primary: "#000",
      placeholder: "#000",
    },

    light: "rgba(0, 0, 0, 0.4)",
    lighter: "rgba(0, 0, 0, 0.2)",
    lightest: "rgba(0, 0, 0, 0.1)",

    warning: "#FF9900",
    danger: "#D93232",
  },
};

export const mergeTheme = (
  theme: Partial<Theme>,
  base: Theme = defaultTheme
): Theme => {
  const merged = merge<Theme, Theme>(base, theme);

  return !merged.color?.start || !merged.color.end
    ? merged
    : merge<Theme, Theme>(merged, {
        color: { mid: mix(0.5, merged.color.start, merged.color.end) },
      });
};
