import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import es from "./locales/es.json";

// FIXME Floating promise!
void i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng: "en",
    debug: false, // Set to true in development
    resources: {
      en,
      es,
    },
    fallbackLng: "en",
  });

export default i18next;
