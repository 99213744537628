import React from "react";

interface ChevronProps {
  color?: string;
  height?: number | string;
  width?: number | string;
}

const Chevron = ({ color, ...props }: ChevronProps) => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 12 20"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 18L10 10L2 2"
      opacity="0.4"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
);

Chevron.defaultProps = {
  stroke: "#7F7F7F",
  height: 12,
  width: 20,
};

export default Chevron;
