import { css } from "@emotion/react";
import React from "react";

import ConicGradient from "./conic-gradient";
import { ColorStop } from "./types";

interface LogoMarkProps {
  size?: number;
  width?: number;
  height?: number;
  borderWidth?: number;
  borderRadius?: number;
  colorStops: ColorStop[];
  style?: any;
}

const LogoMark = ({
  style,
  size = 34,
  width,
  height,
  colorStops = [],
  borderWidth,
  borderRadius,
  ...props
}: LogoMarkProps) => {
  const stops = colorStops.reduce(
    (s, el, i) => `${s + (i === 0 ? "" : ", ")}${el.color} ${el.stop}%`,
    ""
  );

  const gradient = new ConicGradient({
    stops,
    repeating: false,
    size: size * 2,
  });

  return (
    <div
      {...props}
      css={css`
        background-image: url("${gradient.canvas.toDataURL()}");
      `}
    />
  );
};

LogoMark.defaultProps = {
  width: 34,
  height: 34,
};

export { LogoMark };
