import { SerializedStyles, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ReactNode } from "react";

import FlexaLogo from "../FlexaLogo";
import Footer from "../Footer";
import Header from "../Header";
import Link from "../Link";
import Spacer from "../Spacer";
import Wrapper from "../Wrapper";

interface LayoutProps {
  children?: ReactNode;
  header?: any;
  pageWrapperCss?: SerializedStyles;
}

/*
Full Width 1600
|-Gutter 168-|-Main 1270-|-Gutter 168-|
*/

const SiteWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  flex: 1 0 76%;
  width: 100%;
`;

const Gutter = styled.div`
  flex: 1 1 12%;
  position: relative;
  max-width: 10.5rem;
  min-width: 5rem;
  padding-left: var(--unit);
  padding-right: var(--unit);
`;

const Feet = styled.div`
  height: 10px;
  width: 100%;
  background-image: linear-gradient(
    17.51deg,
    var(--gradient-start) 0%,
    var(--gradient-end) 100%
  );
`;

// 24.75, 24.76, 24.8, 39.37, 63.54, 74.86, 94.74

const makeColorStops = (colors: string[]) =>
  // return [0, 31, 50, 50, 62, 91, 0].reduce((stops, stop, i) => {
  [0, 0.01, 0.05, 14.62, 38.79, 50.11, 70, 100].reduce((stops, stop, i) => {
    stops.push({
      color: colors[i % 7],
      stop,
    });
    return stops;
  }, []);

const Main = styled.main`
  flex: 1;
`;

const Layout = ({
  children,
  header = {},
  pageWrapperCss = css`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  `,
}: LayoutProps) => {
  const theme = useTheme();

  const colorStops = makeColorStops(theme.color.logoStops);

  const { content: headerContent, ...headerProps } = header;

  return (
    <>
      <SiteWrapper
        css={css`
          position: fixed;
          z-index: 1;
          height: 0;
        `}
      >
        <Gutter
          css={css`
            height: 100vh;
          `}
        >
          <div
            css={css`
              position: relative;
              top: 3.75rem;
              height: var(--menu-height);

              display: flex;
              justify-content: center;

              z-index: 1;
            `}
          >
            <Link to="/">
              <FlexaLogo colorStops={colorStops} size={48} />
            </Link>
          </div>
        </Gutter>
        <Content></Content>
        <Gutter />
      </SiteWrapper>

      <div css={pageWrapperCss}>
        <Spacer rem={3.75}></Spacer>

        <Wrapper
          css={css`
            z-index: 1;
          `}
        >
          <Header {...headerProps}>{headerContent}</Header>
        </Wrapper>

        <Main>{children}</Main>

        <Wrapper>
          <Footer />
        </Wrapper>

        <Feet></Feet>
      </div>
    </>
  );
};

export default Layout;
