import { css } from "@emotion/react";
import React from "react";

import { FancyButton, FancyButtonProps } from "./Button";
import Spacer from "./Spacer";
import SubTitle from "./SubTitle";
import { H1 } from "./Text/Text";

export interface PageHeaderProps {
  title: JSX.Element | string;
  subtitle?: JSX.Element | string;
  buttonProps?: FancyButtonProps;
}

const PageHeader = ({
  title,
  subtitle,
  buttonProps,
  ...props
}: PageHeaderProps) => (
  <header {...props}>
    <H1
      css={css`
        max-width: 90%;
        margin-bottom: 1.6rem;
      `}
    >
      {title}
    </H1>
    <SubTitle>{subtitle}</SubTitle>

    {buttonProps ? (
      <>
        <Spacer rem={3.5} />
        <FancyButton gradient {...buttonProps} />
      </>
    ) : null}
  </header>
);

export default PageHeader;
