import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem } from "polished";
import React, { ComponentType } from "react";

import Link from "../Link";
import { Caret } from "../SVG";

const fancyButtonBaseCss = css`
  height: 4.5rem;
  font-size: ${rem(24)};
  font-weight: 600;

  outline: none;
  border: none;
  border-radius: 0.675rem;

  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  background: var(--highlight-color);

  & path {
    stroke: #fff;
  }

  /* box-shadow: 0 4px 16px 0 #ffffff; */
`;

const gradientBackgroundCss = css`
  background-image: linear-gradient(
    90.32deg,
    var(--gradient-start) 0%,
    var(--gradient-end) 100%
  );
`;

/* var(--gradient-end) 207.97% */

const mutedButtonCss = css`
  /* box-shadow: 0 4px 16px 0 #ffffff; */

  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.92);
  }

  & span {
    z-index: 1;
    color: var(--highlight-color);
  }

  & path {
    stroke: var(--highlight-color);
  }
`;

const whiteButtonCss = css`
  background: rgba(255, 255, 255, 0.2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & path {
    stroke: #fff;
  }
`;

// const MutedButton = css`
//   ${buttonBase};
// `

const FancyButtonBase = styled(Link, {
  shouldForwardProp: (prop) =>
    (isPropValid(prop as string) && prop !== "gradient") || prop === "external",
})`
  ${fancyButtonBaseCss};
  ${(props) => props.white && whiteButtonCss};
  ${(props) => props.gradient && gradientBackgroundCss};
  ${(props) => props.muted && mutedButtonCss};
`;

const ButtonText = ({ children, caret = true }) => (
  <span
    css={css`
      color: #fff;
      padding-left: 1.75rem;
      padding-right: 1.75rem;

      display: flex;
      align-items: center;

      white-space: nowrap;
    `}
  >
    {children}
    {caret ? (
      <Caret
        css={css`
          width: 0.675rem;
          margin-left: 0.625rem;
          margin-top: 0.375rem;
        `}
      />
    ) : null}
  </span>
);

const FancyButtonIconContainer = styled.span`
  width: ${rem(76)};
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.2);
`;

const ButtonIcon = ({ icon }) => {
  const Icon = icon;
  return (
    <FancyButtonIconContainer>
      <Icon
        css={css`
          width: ${rem(28)};
          height: ${rem(28)};
        `}
      />
    </FancyButtonIconContainer>
  );
};

interface FancyButtonOptions {
  white?: boolean;
  gradient?: boolean;
  muted?: boolean;
}

export type FancyButtonProps = FancyButtonOptions & {
  title: string;
  href?: string;
  to?: string;
  as?: any;
  icon?: ComponentType | string;
  onClick?: () => void;
};

export const FancyButton = ({
  title = "Click me!",
  href,
  icon,
  ...props
}: FancyButtonProps) => (
  <FancyButtonBase {...props}>
    <ButtonText caret={!icon}>{title}</ButtonText>
    {icon ? <ButtonIcon icon={icon} /> : null}
  </FancyButtonBase>
);
