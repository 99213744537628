import styled from "@emotion/styled";

import Box from "../Box";

export interface FlexProps {
  column?: boolean;
  start?: boolean;
  end?: boolean;
  between?: boolean;
  center?: boolean;
  justify?: string;
  align?: string;
}

const parseJustify = (props: FlexProps) => {
  const { justify, center, start, end, between } = props;
  return (
    justify ??
    ((between && "space-between") ||
      (start && "flex-start") ||
      (end && "flex-end") ||
      (center && "center"))
  );
};

const Flex = styled(Box)`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  justify-content: ${parseJustify};
  align-items: ${(props) => props.align};
`;

Flex.defaultProps = {
  center: true,
  align: "center",
};

export default Flex;

export const CenterFlex = styled(Flex)``;

export const TwoCol = styled(Flex)`
  & > div {
    width: 50%;
  }

  @media only screen and (max-width: 520px) {
    & > div {
      width: 100%;
    }
  }
`;

export const Left = styled(Flex)`
  justify-content: flex-start;
`;

export const Right = styled(Flex)`
  justify-content: flex-end;
`;

export const Center = styled(Flex)`
  justify-content: center;
`;
