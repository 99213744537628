const FakeBackend = {
  allocations: [
    {
      appId: "spedn",
      amount: "10000000000000000000000",
    },
  ],
  unallocated: "20000000000000000000000",

  total: "30000000000000000000000",

  transactions: [
    {
      type: "supply",
      createTime: new Date("2019-10-16T09:00:00"),
      transactionHash: "0xabc",
      complete: true,
      amount: 30000,
    },
  ],

  init: async () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            ok: true,
          },
        });
      }, 1000);
    }),

  async getAccount() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            allocations: this.allocations,
            unallocated: this.unallocated,
            withdrawalAuthorizationLimit: this.unallocated,
            requestedWithdrawalAuthorization: this.unallocated,
          },
        });
      }, 1000);
    });
  },

  async getAccountTransactions() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {
            transactions: this.transactions,
          },
        });
      }, 1000);
    });
  },

  updateAccount: async () =>
    // FIXME Why not just Promise.resolve()?
    new Promise<void>((resolve) => {
      resolve();
    }),
};

export default FakeBackend;
