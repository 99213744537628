import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ReactNode } from "react";

/*
Full Width 1600
|-Gutter 168-|-Main 1270-|-Gutter 168-|
*/

interface WrapperProps {
  children?: ReactNode;
  contentCss?: SerializedStyles;
}

const Container = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
`;

const Content = styled.div`
  flex: 1 0 76%;
  width: 100%;
`;

const Gutter = styled.div`
  flex: 1 1 12%;
  position: relative;
  max-width: 10.5rem;
  min-width: 5rem;
  padding-left: var(--unit);
  padding-right: var(--unit);
`;

const Wrapper = ({ children, contentCss }: WrapperProps) => (
  <>
    <Container>
      <Gutter></Gutter>
      <Content css={contentCss}>{children}</Content>
      <Gutter></Gutter>
    </Container>
  </>
);

export default Wrapper;
