import styled from "@emotion/styled";
import { rem } from "polished";

import Flex from "../Flex";

export const Buttons = styled(Flex)`
  width: 100%;

  & > * {
    margin-left: ${rem(24)};
  }

  & > *:first-child {
    margin-left: 0;
  }
`;
