import { selector, selectorFamily } from "recoil";

import { configSelector } from "../config";

export interface AppProps {
  appId: string;
  name: string;
  color: string;
  logo?: string;

  partition: string;
}

export const appDataAtom = selector({
  key: "appData",
  get: ({ get }) => {
    const { apps } = get(configSelector);
    return apps;
  },
});

export const appDataAtomByPoolType = selectorFamily({
  key: "appData",
  get:
    (poolType: string) =>
    ({ get }) =>
      get(appDataAtom).filter((app) => app.poolType === poolType),
});

export const appSelector = selectorFamily({
  key: "appSelector",
  get:
    (appId: string) =>
    ({ get }) => {
      const apps = get(appDataAtom);
      return apps.find((a) => a.appId === appId) ?? null;
    },
});
