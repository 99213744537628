// require('react-dom')
// window.React2 = require('react')
// console.log(window.React1 === window.React2)
import { Global, css } from "@emotion/react";
import { normalize } from "polished";
import React from "react";

// import { above, spacer } from '~/utils/styles'
import "../../styles/fonts/1805-TITXUQ.css";

const GlobalStyles = () => (
  <Global
    styles={(theme) => css`
      ${normalize()}

      :root {
        --unit: 16px;

        --body-color: #000;
        --text-color: #444;

        --highlight-color: ${theme.color.highlight};
        --gradient-start: ${theme.color.start};
        --gradient-end: ${theme.color.end};

        --menu-height: 3rem;

        --break-mobile: 520px;
      }

      @media only screen and (max-width: 1600px) {
        :root {
          --unit: 14px;
        }
      }

      @media only screen and (max-width: 1200px) {
        :root {
          --unit: 12px;
        }
      }

      @media only screen and (max-width: 1024px) {
        :root {
          --unit: 10px;
        }
      }

      @media only screen and (max-width: 768px) {
        :root {
          --unit: 8px;
        }
      }

      @media only screen and (max-width: 520px) {
        :root {
          --unit: 7px;
        }
      }

      html {
        font-size: var(--unit);
        font-family: "Graphik LC Web", "Avenir Next", "Helvetica Neue",
          "Segoe UI", "Helvetica", "Arial", "sans-serif";
        line-height: 1.666;
      }

      * {
        box-sizing: border-box;
      }

      img {
        display: block;
        margin-bottom: 0;
      }

      a {
        color: var(--body-color);
        text-decoration: none;
      }

      body {
        width: 100vw;
      }

      h1 {
        font-size: 6rem;
        font-weight: 600;
        line-height: 1.1;
      }

      h2 {
        font-size: 3.75rem;
        font-weight: 500;
        line-height: 1.4;
      }
      h3 {
        font-size: 2.25rem;
        font-weight: 500;
        line-height: 1.4;
      }
      p {
        color: var(--body-color);
        margin-bottom: 2rem;
      }
    `}
  />
);

export default GlobalStyles;
