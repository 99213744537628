import { atom, selector } from "recoil";

import { getConfiguration } from "../modules/api";
import { configSelector } from "../modules/config";

export const execGetConfiguration = async (options) => {
  if (!options?.baseUrl) {
    return { ok: false };
  }

  const { data, error } = await getConfiguration(options);
  if (error) {
    throw error;
  }

  return data;
};

const initStateQuery = selector({
  key: "initStateQuery",
  get: async ({ get }) => {
    const { apiUrl } = get(configSelector);
    try {
      const data = await execGetConfiguration({ baseUrl: apiUrl });
      return {
        ...data,
        loaded: true,
      };
    } catch (error) {
      return {
        loaded: true,
        ok: false,
        ipAddress:
          error?.response?.data?.ipAddress ?? error?.data?.ipAddress ?? "",
      };
    }
  },
});

export const initStateAtom = atom({
  key: "initState",
  default: initStateQuery,
});

export const isInitializedSelector = selector({
  key: "isInitialized",
  get: ({ get }) => {
    const state = get(initStateAtom);
    return state?.loaded ?? false;
  },
});

export const initIpAddressSelector = selector({
  key: "initIpAddress",
  get: ({ get }) => {
    const state = get(initStateAtom);
    return state?.ipAddress ?? "";
  },
});
