import { css } from "@emotion/react";
import { rem } from "polished";
import React, { ComponentProps } from "react";

const Header = (props: ComponentProps<"header">) => (
  <header
    css={css`
      position: relative;
      z-index: 10;

      display: flex;
      justify-content: space-between;

      height: ${rem(48)};
    `}
    {...props}
  />
);

export default Header;
