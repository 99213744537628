import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { rem, transparentize } from "polished";
import React, { ComponentType, ReactNode } from "react";

import { BaseProps, pickBg, pickColor } from "../Base";
import { Spacer } from "../Box";

export type ButtonProps = BaseProps &
  StyledLinkProps & {
    children?: ReactNode;
    title?: string;
    icon?: ComponentType;
    iconProps?: any;
    secondary?: boolean;
    disabled?: boolean;

    onClick?: any;
  };

export interface StyledLinkProps {
  as?: React.ElementType;
  to?: string;
}

export const ButtonContainer = styled.div<ButtonProps>`
  outline: none;
  border: none;

  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: ${rem(12)} ${rem(18)};

  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0rem ${rem(1)} ${rem(3)}
    ${(props) =>
      transparentize(0.5, pickColor(props, props.theme?.color?.highlight))};
  border-radius: ${rem(10)};

  color: ${(props) => pickColor(props, props?.theme?.color?.highlight)};

  font-weight: 500;
  font-size: ${rem(20)};
  line-height: ${rem(24)};
  text-align: center;

  transition: all 0.1s;

  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}

  &:hover {
    ${({ disabled, ...props }) =>
      !disabled &&
      css`
        box-shadow: 0rem ${rem(2)} ${rem(8)}
          ${transparentize(
            0.5,
            pickColor(props, props.theme?.color?.highlight)
          )};
      `}
  }
`;

export const Button = ({
  title,
  icon,
  iconProps = {},
  children,
  disabled,
  onClick,
  ...props
}: ButtonProps) => {
  const IconComponent = icon;
  const handleClick = !disabled ? onClick : (e) => e.preventDefault();
  const { css: iconCss } = iconProps;
  return (
    <ButtonContainer {...props} disabled={disabled} onClick={handleClick}>
      {children || (
        <>
          {icon ? (
            <>
              <IconComponent
                css={[
                  css`
                    width: ${rem(24)};
                    height: ${rem(24)};
                  `,
                  iconCss,
                ]}
                {...iconProps}
              />
              <Spacer col size={10} />
            </>
          ) : null}

          {title}
        </>
      )}
    </ButtonContainer>
  );
};

export const SecondaryButton = (props: ButtonProps) => {
  const { color } = useTheme();
  const bgColor = pickBg(props, color?.highlight);
  return (
    <Button
      css={css`
        color: #fff;
        background: ${bgColor};
        box-shadow: 0rem ${rem(1)} ${rem(3)} ${transparentize(0.5, bgColor)};

        &:hover {
          box-shadow: 0rem ${rem(2)} ${rem(8)} ${transparentize(0.5, bgColor)};
        }
      `}
      {...props}
    />
  );
};
