import styled from "@emotion/styled";
import { rem } from "polished";
import React from "react";

import Link from "../Link";

export interface NavMenuItemProps {
  name: string;
  href?: string;
  external?: boolean;
  childs?: NavMenuItemProps[];
}

export interface NavMenuProps {
  items?: NavMenuItemProps[];
  right?: boolean;
}

const NavUL = styled.ul`
  list-style: none;

  padding: 0;
  margin: 0;
`;

export const NavMenu = styled(NavUL)<NavMenuProps>`
  width: 100%;
  height: var(--menu-height);

  display: flex;
  justify-content: ${({ right }) => (right ? "flex-end" : "flex-start")};
  align-items: center;

  & > li:first-of-type a {
    margin-left: calc(-2 * var(--unit));

    @media only screen and (max-width: 520px) {
      margin-left: 0;
    }
  }

  & > li:last-of-type a {
    margin-right: calc(-2 * var(--unit));

    @media only screen and (max-width: 520px) {
      margin-left: 0;
    }
  }
`;

const SubMenuContainer = styled.div`
  z-index: 2;
  display: none;
  position: absolute;
  margin-top: -1rem;
  padding-top: 2rem;

  min-width: 17rem;
`;

const SubMenu = styled(NavUL)`
  border-radius: 1.5rem;
  box-shadow: 0 4px 12px 0 rgba(72, 93, 255, 0.1),
    0 12px 48px 0 rgba(72, 93, 255, 0.15);
  background-color: #ffffff;

  padding: 1rem;

  left: 0;
`;

interface NavLinkProps {
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  to?: string;
}

export const NavLink = styled.span<NavLinkProps>`
  display: block;

  border-radius: 0.75rem;

  /* display: block; */
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: 1rem;
  padding-bottom: 1rem;

  font-weight: 500;
  font-size: ${rem(24)};

  @media only screen and (max-width: 1600px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 520px) {
    font-size: 1.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const NavLI = styled.li`
  --padding: calc(2 * var(--unit));
  margin: 0;

  position: relative;
  color: #000;

  word-break: normal;

  font-weight: 500;
  font-size: calc(1.25 * var(--unit));

  &:hover > ${NavLink} {
    opacity: 0.8;
    /* color: ${(props) => props.theme.color.highlight}; */
  }

  &:hover ${SubMenuContainer} {
    display: block;
    cursor: pointer;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 520px) {
    font-size: 1.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const SubNavItem = styled(NavLI)`
  --padding: 1rem;

  & ${NavLink} {
    display: block;
    padding-left: var(--padding);
    padding-right: var(--padding);

    font-weight: 500;
    font-size: calc(1.25 * var(--unit));
  }

  & ${NavLink}:hover {
    background-color: rgba(72, 93, 255, 0.05);
  }
`;

export const NavItem = ({ name, href, external, childs }: NavMenuItemProps) => {
  const props = href ? { to: href, as: Link, external } : {};

  return (
    <NavLI key={name}>
      <NavLink {...props}>{name}</NavLink>
      {childs ? (
        <SubMenuContainer>
          <SubMenu>
            {childs.map(({ name, href, external }) => {
              const props = href ? { to: href, as: Link, external } : {};
              return (
                <SubNavItem key={name}>
                  <NavLink {...props}>{name}</NavLink>
                </SubNavItem>
              );
            })}
          </SubMenu>
        </SubMenuContainer>
      ) : null}
    </NavLI>
  );
};

const Nav = ({ items }: NavMenuProps) => (
  <NavMenu>
    {items.map((props) => (
      <NavItem {...props} />
    ))}
  </NavMenu>
);

export default Nav;
